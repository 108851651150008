<template>
  <!-- 报警事件 -->
  <div class="purchaseManage">
    <!-- 板块中心区域 -->
    <div class="purchaseManage_mid">
      <div class="purchaseManage_header">
        <!-- 标题 -->
        <div class="title">
          <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="" />
          <p v-for="(item, index) in $route.meta" :key="index">{{ item }}</p>
        </div>
        <!-- 选项框和按钮 -->
        <div class="option_btn">
          <div class="options">
            <!-- Element ui--Select 选择器 -->
            <!-- 信息查询 -->
            <template>
              <span style="margin: 0 0.8vw 0 1.92vw">信息查询</span>
              <el-input v-model="search" placeholder="请输入网关编号" class="production"></el-input>
              <el-button size="small" type="primary" @click="searchList">查询</el-button>
            </template>
          </div>
          <div class="flushed">
            <img style="width: 50%;height: 50%;" src="../../assets/image/warningInform_slices/group_342@2x.png" alt="" @click="flushed"  />
          </div>
        </div>
      </div>

      <!-- 表格区域 -->
      <div class="gateway_form">
        <!-- 报警事件-- 表格区域 -->
        <div class="lists">
          <!-- Element ui -- Form表单 -->
          <template>
            <el-table :data="allData" style="width: 100%" :header-cell-style="{ background: '#4888FF' }" :max-height="tableHeight"
              v-loading="loading" :row-class-name="tableRowClassName">
              <!-- 索引序号 -->
              <el-table-column type="index" width="80" fixed label="序号"></el-table-column>
              <!-- 网关编号 -->
              <el-table-column prop="nid" label="网关编号" min-width="220">
              </el-table-column>
              <!-- 网关名称 -->
              <el-table-column prop="nname" label="网关名称" min-width="200">
              </el-table-column>
              <!-- 离线时间 -->
              <el-table-column prop="time" label="离线时间" min-width="200">
              </el-table-column>
              <!-- 安装区域 -->
              <el-table-column prop="naddr" label="安装区域" min-width="200">
                <template #default="{row}"><span>{{row.marea ? row.marea + '/' : '-'}}</span><span>{{row.mfloor ? row.mfloor + '/' : '-'}}</span><span>{{row.mceng}}</span></template>
              </el-table-column>
              <!-- 安装地址 -->
              <el-table-column prop="naddr" label="安装地址" min-width="200">
              </el-table-column>
              <div slot="empty" style="text-align: center">
                <el-empty description="没有找到离线记录哦~" />
              </div>
            </el-table>
          </template>
          <!-- Element ui--Pagination分页 -->
          <template>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="datas.page" :page-sizes="[100, 200, 300, 400]" :page-size="datas.size"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { offequipment } from "@/utils/api";
export default {
  computed: {},
  data () {
    return {
      loading: true, //刷新数据
      total: 0,
      datas: {
        page: 1, //当前页
        size: 100, //数据量
      },
      search: "", //查询信息关键字
      fullscreenLoading: false,
      allData: [], //列表数据
      tableData: [],
      tableHeight: 0
    };
  },
  created() {
    this.tableHeight = document.documentElement.clientHeight - 300;
    this.getList();
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.tableHeight = document.documentElement.clientHeight - 300;
    })
  },
  methods: {
    //获取列数据
    getList () {
      this.loading = true;  
      offequipment({
        page: this.datas.page,
        size: this.datas.size,
        nid: this.search,
        type: 2
      }).then((res) => {
        this.allData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    //分页
    handleSizeChange (size) {
      this.datas.size = size;
      this.getList();
    },
    handleCurrentChange (page) {
      this.datas.page = page;
      this.getList();
    },
    //搜索
    searchList () {
      let Reg = /^(([a-zA-Z0-9]){8}-([a-zA-Z0-9]){8}-([a-zA-Z0-9]){8})$/;
      if (!Reg.test(this.search)) {
        this.$message("请输入正确网关编号");
        return;
      }
      this.getList();
    },
    flushed () {
      this.search = ''
      this.getList();
    },
    //隔行变色
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<style scoped>
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}
.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}
.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-size: 14px;
  font-weight: normal;
}
.lists .el-pagination {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Source Han Sans CN-Regular";
}
.lists /deep/ .el-pagination__jump {
  margin-left: 0;
}
.el-table /deep/ .el-table__cell {
  text-align: center;
  padding: 16px 0;
}
.purchaseManage {
  height: 100%;
  display: flex;
  justify-content: center;
  margin: -30px 0 0 0;
}
.purchaseManage .purchaseManage_mid {
  width: 100%;
  height: 100%;
}
/* 头部--开始 */
.purchaseManage_mid .purchaseManage_header {
  width: 100%;
  min-height: 134px;
  background-color: #fff;
}
.purchaseManage_header .title {
  display: flex;
  align-items: flex-end;
}
.purchaseManage_header .title img {
  width: 6px;
  height: 20px;
  margin: 20px 0 0 20px;
}
.purchaseManage_header .title p {
  font-size: 20px;
  font-family: "SHSCN_Bold";
  margin: 1vw 0 0 0.6vw;
}
/* 选项框和按钮 */
.option_btn {
  width: 100%;
  height: 7.2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option_btn /deep/ .el-input {
  width: 10.7vw;
  height: 2.96vh;
}
.options {
  width: 500px;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.options .el-button {
  margin:6px 0 0 40px;
}
.option_btn /deep/ input {
  width: 206px;
  height: 32px;
}
.option_btn /deep/ .el-input__icon {
  line-height: 1.85vh;
}
.option_btn span {
  font-size: 16px;
  font-family: "SHSCN_Regular";
}
/* 刷新数据图片 */
.flushed {
  margin: 25px 0 0 0;
}
.flushed img {
  cursor: pointer;
}
/* 头部--结束 */

/* 表格区域--开始 */
.purchaseManage_mid .gateway_form {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: 5px;
}
/* 表格区域--结束 */
.lists {
  height: 100%;
  position: relative;
  background-color: #fff;
}
.lists /deep/ .el-pagination {
  position: absolute;
  bottom: 100px;
  right: 0;
  margin-top: 14px;
  height: 50px !important;
}
</style>
